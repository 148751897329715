import { Suspense, lazy, React, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PageLoader from "./Components/Loaders/PageLoader";
import MaintainancePage from "./Pages/Maintainance/MaintainancePage";
import MainRoutes from "./Routes/MainRoutes";
import { PrivateRoutes } from "./Routes/PrivateRoutes";
import useVerifyUser from "./redux-setup/custom-hooks/useVerifyUser";
import useVersionCheck from "./redux-setup/custom-hooks/useVersionCheck";
import { useSelector } from "react-redux";
import { Toaster } from "react-hot-toast";
import Footer from "./Components/Footer/Footer";

const Sidebar = lazy(() => import("./Components/Sidebar/Sidebar"));
const Header = lazy(() => import("./Components/Header/Header"));
const Home = lazy(() => import("./Pages/Home/Home"));
const Login = lazy(() => import("./Pages/Auth/Login"));
const Profile = lazy(() => import("./Pages/Profile/Profile"));
const Complaints = lazy(() => import("./Pages/Complaints/Complaints"));
const ComplaintDetails = lazy(() =>
  import("./Pages/Complaints/ComplaintDetails/ComplaintDetails")
);

const App = () => {
  const [isMaintainance, setisMaintainance] = useState(false);
  const [mainLoader, setmainLoader] = useState(true);
  const user = useSelector((state) => state.user);
  const refreshToken = localStorage.getItem("token****");
  const switchedSociety = localStorage.getItem("society****");

  // Custom hook for version check
  useVersionCheck(setisMaintainance);
  // Custom hook for verify user
  useVerifyUser(refreshToken, setmainLoader, switchedSociety);

  if (isMaintainance) {
    return <MaintainancePage />;
  }
  if (mainLoader) return <PageLoader />;



  return (
    <div className="wrapper">
      {user && <Sidebar />}
      <div className="wrapper-main">
        {user && <Header />}

        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route
              path={MainRoutes.LOGIN}
              element={user ? <Navigate to={MainRoutes.HOME} /> : <Login />}
            />
            <Route element={<PrivateRoutes user={user} />}>
              <Route path={MainRoutes.HOME} element={<Home />} />
              <Route path={MainRoutes.PROFILE} element={<Profile />} />
              <Route path={MainRoutes.COMPLAINT} element={<Complaints />} />
              <Route
                path={MainRoutes.COMPLAINTDETAILS}
                element={<ComplaintDetails />}
              />
            </Route>
          </Routes>
        </Suspense>
        <Footer />
        <Toaster
          position="top-center"
          reverseOrder={false}
          //  gutter={3}
          toastOptions={{
            // Define default options
            className: "",
            duration: 5000,
          }}
        />
      </div>
    </div>
  );
};

export default App;
