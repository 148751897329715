import { useEffect } from "react";
import { useDispatch } from "react-redux";
import ApiCall from "../../Api/ApiCall";
import { AuthEndPoints } from "../../Api/Endpoints";
import { actionCreators } from "..";
import toast from "react-hot-toast";
import {
  LoadingToasterMessages,
  SuccessToasterMessages,
} from "../../Helpers/toasterMessage";

const useVerifyUser = (refreshToken, setmainLoader,switchedSociety) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const handleAuthenticateUser = async () => {
      // const loadingToastId = toast.loading(LoadingToasterMessages.USERVERIFY);
      if (refreshToken) {
        try {
          console.log("***Logged in Success.Token Verified***");
          const res = await ApiCall("get", AuthEndPoints.verifyUser, {
            params: { token: refreshToken,
              societyId:switchedSociety,
            },
          });
          if (res?.success) {
            // toast.dismiss(loadingToastId);
            // toast.success(SuccessToasterMessages.USERVERIFIED);
            dispatch(actionCreators.addAuthenticateUser(res.user));
            dispatch(
              actionCreators.addNamingConvention(
                res.user?.config?.propertyNaming
              )
            );
          }
        } catch (error) {
          console.error("Error verifying user", error);
        } finally {
          setmainLoader(false);
          // toast.dismiss(loadingToastId);
        }
      } else {
        setmainLoader(false);
      }
    };

    handleAuthenticateUser();
  }, [refreshToken, dispatch]);
};

export default useVerifyUser;
